import React from 'react';

const RenderMultipleCells = ({ data }) => data.map((item, index) => (
  <div key={`ball_${index.toString()}`}>
    {
    Array.isArray(item) ? item.map(subItem => {
      if (subItem.image) {
        return <div className="innerCellWrapper"><span className={subItem.className}><img src={subItem.image} alt="" /></span></div>;
      }
      return <div className="innerCellWrapper"><span className={subItem.className}>{subItem.value}</span></div>;
    })
      : item.image ? <span className={item.className}><img src={item.image} alt="" /></span> : <span className={item.className}>{item.value}</span>
    }
  </div>
));

export default RenderMultipleCells;